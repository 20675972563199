/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:ce304c6e-a768-4de1-87c7-7f97c0618106",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ueqrgbcXn",
    "aws_user_pools_web_client_id": "5mcehtt7720q04v71rtmcq15a9",
    "oauth": {
        "domain": "scrjgame-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://game.lakers.ch/user/verify/,https://dev.game.lakers.ch/user/verify/,http://localhost:3000/user/verify/",
        "redirectSignOut": "https://game.lakers.ch/,https://dev.game.lakers.ch/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://7cqziwt3jnfn5kg7ty4qc4fnrq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "externalservices",
            "endpoint": "https://jagckg8840.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "scrjgame1945mediafiles155038-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
